
/****************
LOADING BAR
****************/
.loading {
    position: absolute;
    margin: 200px auto 0;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    z-index: 9999;
    border: 12px solid #f3f3f3; /* Light grey */
    border-top: 12px solid #666666; /* dark grey */
    border-radius: 50%;
    box-shadow: 0 1px 0 rgba(255, 255, 255, .03), inset 0 1px 10px rgba(0, 0, 0, .1);
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;

  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
