/* tooltip */

.tooltip {
    position: relative;
    background: rgba(0, 0, 0, .7) !important;
    border-radius: 4px;
    color: #fff;
    padding: 4px 8px;
    opacity: .9 !important;
    white-space: nowrap;
    z-index: 990
  }

  .tooltip-measure {
    opacity: 1 !important;
    font-weight: bold
  }

  .tooltip-static {
    background-color: rgba(0, 0, 0, .7) !important;
    color: #fff;
    box-shadow: 0 0 0 3px rgba(134, 142, 150, .5)
  }

  .tooltip-measure:before,
  .tooltip-static:before {
    border-top: 6px solid rgba(0, 0, 0, .7);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%
  }

  .tooltip-static:before {
    border-top-color: rgba(0, 0, 0, .7)
  }

