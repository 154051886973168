
#pageSlider{
    width: 90%;
}

.slider_label {
    padding-left: 2px;
    padding-right: 2px;
}

.consultform_hidden {
    display: none;
}

.consult_page {
    width:100%;
}

.consult_page_hide {
    display: none;
    margin-bottom:0.5em;
}

.consult_page_show {
    display: block;
}

.consult_error {
    color: red;
    font-weight:bold;
    display:none;
}

.reqfield {
    color: red;
    font-weight:bold;
    display:none;
}

.consult_text_val {
    width:100%;
    margin-bottom:0.5em;
}

#consultBack, #consultNext, #consultFinish {
    margin-bottom: 3px;
    width: 100%;
}

.consult_section {
    padding-bottom:0.5rem;
    padding-top:0.5rem;
    border-bottom: 1px solid #dee2e6;
}

.consult_col1 {
    border-right: 1px solid #dee2e6;
}

.consult_question {
    text-align: left;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}


.consulttimebox {
    width:100%;
    margin-bottom:0.5em;
}

.timegroup {
    width: 100%;
}

.consultDownload {
    display: none;
}

.askbutton {
    margin-bottom: 5px;
    background-color: green;
    border: solid 1px green;
}

.hcheck1 {
    margin-left: 10px !important;
}

.hcheck {
    margin-right: 10px !important;
}

.consult_radio_val_h {
    margin-left: 0.4rem !important
}

.radio_col {
    padding-top: 4px;
    padding-left: 15px;
    padding-right: 5px;
}

.buttoncontainer {
    padding-top: 3px;
}

.expirewarning {
    font-size: 2rem;
    font-weight: bold;
    color: white;
    background-color: red;
    border-color: #F0A9B0;
    border-radius: 0.25rem;
    border-width:5px;
}
