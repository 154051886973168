//IE 11 Fallback and mobile theme
.navbar {
    background:black;
    color: white;
}
.modal-header,
.navbar-nav .nav-item .nav-link {
    background:black;
    color: white;
    border: none;
}
.navbar-nav .nav-item .nav-link:focus,
.navbar-nav .nav-item .nav-link:hover {
    color: white;
    cursor: pointer;
}
.navbar-toggler {
    border-color: white;
}
.fa-bars {
    color: white;
}
.fa-bars:hover {
    color: white;
}
.close span,
.modal-header {
    background: black;
    color: white;
}
//END OF IE11 Fallback
h1 {
    padding-top: 0px;
    color: white;
    font-size: 2.0rem;
}

@media (max-width: 767px) {
    h1 {
        font-size: 1.5rem;
        padding-top: 5px;
    }
}

// DESKTOP THEME USING CLIENT COLOURS
@media (min-width: 768px) {
    h1 {
        padding-top: 5px;
        color: var(--font-color);
    }
    .navbar {
        background:var(--back-color);
    }

  .modal-header,
  .navbar-nav .nav-item .nav-link {
      background:var(--back-color);
      color: var(--font-color);
      border: none;
  }
  .navbar-nav .nav-item .nav-link:focus,
  .navbar-nav .nav-item .nav-link:hover {
      color: var(--font-highlight);
      background:var(--back-highlight);
      border-radius: 0.25rem;
      cursor: pointer;
  }
  .navbar-toggler {
      border-color: var(--border-color);
  }
  .fa-bars {
      color: var(--font-color);
  }
  .fa-bars:hover {
      color: var(--font-color);
  }
  .close span,
  .modal-header {
      background: var(--back-color);
      color: var(--font-color);
  }
}
